import { createGlobalStyle } from 'styled-components';
import { fontSizer } from '.';
import { getTheme } from './theme';

const generateFontStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i += 2) {
    arr.push(`.font-${i}{font-size: ${i}px !important;}`);
  }
  return arr;
};

const generatePaddingStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i += 2) {
    const css = `
    .p-${i}{padding: ${i}px;} 
    .px-${i} {
      padding-left: ${i}px;
      padding-right: ${i}px;
    }
    .py-${i} {
      padding-top: ${i}px;
      padding-bottom: ${i}px;
    }
    `;
    arr.push(css);
  }
  return arr;
};

const generateMarginStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i += 2) {
    const css = `
    .m-${i}{margin: ${i}px;}
    .ml-${i}{margin-left: ${i}px;}  
    .mr-${i}{margin-right: ${i}px;}
    .mb-${i}{margin-bottom: ${i}px;}
    .mt-${i}{margin-top: ${i}px;}
    .mx-${i} {
      margin-left: ${i}px;
      margin-right: ${i}px;
    }
    .my-${i} {
      margin-top: ${i}px;
      margin-bottom: ${i}px;
    }
    `;
    arr.push(css);
  }
  return arr;
};

const GlobalStyles = createGlobalStyle`
:root {
  --primary: #0058ff;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
}

/* display */
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.flex1 { 
  flex: 1;
}
.inline-flex {
  display: inline-flex;
}

/* flex */
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1 1 0%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* justify-content */
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

/* align-items */
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}

/* user-select */
.select-none {
  user-select: none;
}
.select-auto {
  user-select: none;
}

/* text-align */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

/* text-overflow */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* font-weight */
.bold {
  font-family: "Pretendard-Bold";
}
.black {
  font-family: "Pretendard-Black";
}
.bold-300 {
  font-weight: 300;
}
.bold-400 {
  font-family: "Pretendard-Regular";
}

.font-number {
  font-family: 'Roboto', 'Noto Sans KR';
}

.font-media-14 {
  ${fontSizer(14)};
}
.font-media-16 {
  ${fontSizer(16)};
}
.font-media-18 {
  ${fontSizer(18)};
}
.font-media-20 {
  ${fontSizer(20)};
}
.font-media-24 {
  ${fontSizer(24)};
}
.font-media-28 {
  ${fontSizer(28)};
}
.font-media-30 {
  ${fontSizer(30)};
}
.font-media-34 {
  ${fontSizer(34)};
}
.font-media-36 {
  ${fontSizer(36)};
}
.font-media-48 {
  ${fontSizer(48)};
}
.font-media-55 {
  ${fontSizer(55)};
}
.font-media-60 {
  ${fontSizer(60)};
}

.c-white {
  color: #ffffff;
}
.c-black {
  color: #000000;
}
.c-primary {
  color: ${getTheme('primary')};
}
.c-primary-neon {
  color: ${getTheme('primary_neon')};
}
.c-gray {
  color: #DAE0E6;
}
.c-green50 {
  color: #00d463;
}

/* margin */
${generateMarginStyles(2, 120)}

.ml-auto {
  margin-left: auto;
}
/* padding */
${generatePaddingStyles(4, 60)}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

.pre-line {
  white-space: pre-line;
}

.width-100 {
  width: 100%;
}

/* font-size */
${generateFontStyles(8, 60)}

/* scroll */
.scroll-visible {
  overflow-x: scroll;
  &:disabled {
    background: none;
  }
  &::-webkit-scrollbar {
    width: 6px;
    visibility: unset;
    display: unset;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #eee;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 516px) {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }
}

&::selection {
  /* background-color: ${getTheme('primary')};
  color: #fff; */
}
#ct-container {
  margin-top: 120px;
}
      
`;

export default GlobalStyles;
