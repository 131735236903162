import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom"; // React Router import
import AppRouter from "./routers/AppRouter";
import GlobalStyles from "./styles/GlobalStyles";
import Helmet from "react-helmet";
import { ReactChannelIO } from "react-channel-plugin";

function App() {
  useEffect(() => {
    window.branch.init(
      "key_live_bBepveKLFVPhfHSMwimiaeihCsiOGqU6",
      {
        enableExtendedJourneysAssist: true,
        extendedJourneysAssistExpiryTime: 5000,
      },
      function (err, data) {
        console.log(err, data);
      }
    );
  }, []);

  return (
    <ReactChannelIO
      pluginKey={"b18eb81c-b7e5-46ee-8f41-410e7085783a"}
      language="ko"
    >
      <Helmet>
        <title>TOUCH MY SECRET, 언시크릿</title>
      </Helmet>

      <Router> {/* Router 컴포넌트로 감싸기 */}
        <AppRouter /> {/* 라우터 컴포넌트 추가 */}
      </Router>

      <GlobalStyles />
    </ReactChannelIO>
  );
}

export default App;