import React from 'react';
import styled from 'styled-components';
import { getTheme, defaultButtonConfig, fontSizer } from 'styles';
import { useNavigate  } from 'react-router-dom';

const NormalButton = styled.button`
  ${({ background }) => defaultButtonConfig(background || getTheme('primary'))};
  justify-content: center;
  align-items: center;

  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`}; 
   
  border-radius: 24px;
  color: ${({ color }) => color};
  

  font-size: ${({fontSize}) => `${fontSize}px`};
  font-family: "Pretendard-Black";

  user-select: none;
`;

function Button({ children, background, to, color="#fff", onClick, width=180, fontSize=18, height=64, ...rest }) {
  const navigate = useNavigate();
  
  function buttonClick () {
    if(to) return navigate(to)
    else return onClick()
  }
  return ( 
    <>
      <NormalButton background={background} color={color} width={width} fontSize={fontSize} height={height} onClick={buttonClick} {...rest}>
        {children}
      </NormalButton>
    </>
  )
}

export default Button;
