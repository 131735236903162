import { css } from 'styled-components';
import { darken } from 'polished';
import { getTheme } from './theme';
export { default as theme } from './theme';
export { getTheme } from './theme';

export const transition = (timing = 0.2) =>
  css`
    -webkit-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;

export const defaultButtonConfig = (backgroundColor = getTheme('primary'), hover = true) => css`
  ${transition()};
  outline: none;
  border: none;
  background-color: ${backgroundColor};
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${hover && darken(0.03, backgroundColor)};
  }
  &:disabled {
    background-color: #bdbdbd;
  }
`;

export const fontSizer = (size, minSize) => {
  return css`
    font-size: ${size + 'px'};
  `;
};
