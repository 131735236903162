import React from 'react';
import styled from 'styled-components';
import { getTheme, fontSizer } from '../../styles';

const Container = styled.div`
  background: ${getTheme('primary50')};
  width: fit-content;
  padding: 35px;
  text-align: center;
  color: #424242;
  line-height: 1.5;
  ${fontSizer(16)};
`;

function SimpleBox({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

export default SimpleBox;
