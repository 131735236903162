import React from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { header_logo } from "assets";

const HeaderWrapper = styled.div`
  width: 100%;
  height: 102px;

  background: #fefefe;
  position: fixed;
  z-index: 999999;
`;

const InnerWrapper = styled.div`
  height: 100%;
  margin: auto;

  max-width: 1600px;
  min-width: 840px; // 1280 + 30 + 30
  padding: 0 30px;

  img {
    height: 52px;
  }

  .line {
    width: 1px;
    height: 36px;

    background-color: #7c7c7c;
  }

  .grey {
    color: #6c7680;
  }

  .active {
    color: #fff;
  }

  .unsecret_friend {
    background-color: #000;
    border-radius: 24px;
  }
`;

function Header() {
  const navigate = useNavigate();

  return (
    <>
      <HeaderWrapper id="top-header">
        <InnerWrapper className="flex-center">
          <img
            className="pointer"
            src={header_logo}
            onClick={() =>
              document
                .getElementById("home_section")
                .scrollIntoView({ behavior: "smooth" })
            }
          />

          <div className="flex-1" />

          <Link
            to="/"
            onClick={() =>
              document
                .getElementById("home_section")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="bold font-16 mr-48"
          >
            홈
          </Link>
          <Link
            to="/"
            onClick={() =>
              document
                .getElementById("company_anker_section")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="bold font-16 mr-48"
          >
            회사 소개
          </Link>
          {/* <a onClick={() => document.getElementById('char_section').scrollIntoView({behavior: "smooth"})} className='bold font-16 mr-48'>프렌즈</a> */}
          {/* <a onClick={() => document.getElementById('adventure_section').scrollIntoView({behavior: "smooth"})} className='bold font-16 mr-48'>어드벤처</a> */}
          <Link
            to="/"
            onClick={() =>
              document
                .getElementById("download_section")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="bold font-16 mr-48"
          >
            언시크릿
          </Link>
          <Link
            className="unsecret_friend py-12 px-32 c-white font-16 bold"
            to="/friend"
          >
            언시크릿 프렌즈
          </Link>
        </InnerWrapper>
      </HeaderWrapper>
    </>
  );
}

export default Header;
