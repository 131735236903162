import React, { Suspense, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LayoutContainer from '../LayoutContainer';
import RouteList from './RouteList';
import ErrorBoundary from './ErrorBoundary';
import { useMobileCheck } from 'hooks';


function AppRouter() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <LayoutContainer>
          <Routes>
            <Route exact path='/' element={<MainRouter/>}/> 
            <Route exact path='/friend' element={<FriendRouter/>}/>
          </Routes>
        </LayoutContainer>
      </Suspense>
    </ErrorBoundary>
  );
}

function MainRouter() {
  const isMobile = useMobileCheck();
  return (
    <>
      {isMobile ? <RouteList.MobileMainPage/> : <RouteList.MainPage/>}
    </>
  )
}

function FriendRouter() {
  const isMobile = useMobileCheck();
  return (
    <>
      {isMobile ? <RouteList.UnsecretFriendPage/> : <RouteList.UnsecretFriendPage/>}
    </>
  )
}

export default AppRouter;
