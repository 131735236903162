import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  background: #eeeeee;
  height: 1px;
  margin: 20px 0;
`;

function Bar() {
  return <Container />;
}

export default Bar;
