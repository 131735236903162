import React from "react";
import styled from "styled-components";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { header_logo } from "assets";

const HeaderWrapper = styled.div`
  width: 100vw;
  height: 72px;

  background: #fefefe;
  position: fixed;
  z-index: 999999;
  top: 0;
`;

const InnerWrapper = styled.div`
  height: 100%;
  margin: auto;
  padding: 0 20px;

  .logo {
    height: 32px;
  }

  .unsecret_friend {
    background-color: #000;
    border-radius: 24px;
  }
`;

function MobileHeader() {
  const navigate = useNavigate();

  return (
    <>
      <HeaderWrapper id="top-header">
        <InnerWrapper className="flex align-center">
          <img
            className="pointer logo"
            src={header_logo}
            onClick={() => {
              navigate("/");
              document
                .getElementById("home_section")
                .scrollIntoView({ behavior: "smooth" });
            }}
          />

          <div className="flex-1" />

          <Link
            className="unsecret_friend py-12 px-16 c-white font-12 bold"
            to="/friend"
          >
            언시크릿 프렌즈
          </Link>
        </InnerWrapper>
      </HeaderWrapper>
    </>
  );
}

export default MobileHeader;
