import React from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { header_logo } from "assets";

const Container = styled.div`
  position: relative;
  background-color: #f4f3f9;
  width: 100%;

  padding: 24px 20px;

  .grey {
    color: #7c7c7c;
  }

  .logo {
    width: 70px;
    height: 32px;
  }
`;

function MobileFooter() {
  const navigate = useNavigate();

  return (
    <Container className="">
      <div className="inner">
        <div className="mb-40">
          <div className="font-14 c-black">
            © 2022 Unsecret All Contents {"&"} Images
            <br />
            Of their respective owners
          </div>
        </div>

        <div>
          <div className="c-primary font-16 black">Contact Us</div>
          <div className="font-14 mt-8">
            알파버스 주식회사 | 대표이사 정재용 |
          </div>
          <div className="font-14 mt-8">사업자등록번호 820-87-02612 |</div>
          <div className="font-14 mt-8">전화번호 070-8693-0393</div>
          <div className="font-14 mt-8">
            부산광역시 해운대구 달맞이길 65번길 20 2층
          </div>
          <div className="font-14 mt-8">(중동, 웰컴하우스)</div>
          <div className="font-14 mt-8">문의(제휴) : hello@unsecret.co.kr</div>
        </div>
      </div>
    </Container>
  );
}

export default MobileFooter;
