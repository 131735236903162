const theme = {
  primary_neon: '#6345fb',
  primary: '#6345fb',
  primary10: '#dbe8ff',
  primary50: '#e7e9f7',
  primary100: '#c1c9eb',
  primary150: '#97a6dd',
  primary200: '#98A6DD',
  primary300: '#6d83d0',
  primary400: '#4b68c6',
  primary800: '#03329a',
  primary900: '#032086',
  gray50: '#FAFAFA',
  gray100: '#F5F5F5',
  gray200: '#EEEEEE',
  gray300: '#E0E0E0',
  gray400: '#BDBDBD',
  gray500: '#9E9E9E',
  gray600: '#757575',
  gray700: '#616161',
  gray800: '#424242',
  gray900: '#212121',
  blueGray50: '#ECEFF1',
  secondary: '#FF5B29',
  secondary50: '#fbe9e8',
  secondary200: '#ffac94',
  secondary800: '#d8471c',
  green50: '#00d463',
  green800: '#1be67a',
};

const fonts = {
  default: `'Noto Sans KR'`,
  serif: `'Noto Serif KR'; serif`,
  number: `Roboto`,
};

export const getTheme = (key) => {
  return key && theme[key] ? theme[key] : theme.gray900;
};

export const getFont = (key) => {
  return key && fonts[key] ? fonts[key] : fonts.default;
};

export default theme;
