import React from 'react';
import styled, { css } from 'styled-components';
import { Header, MobileHeader, Footer, MobileFooter } from 'components';

import { useMobileCheck } from 'hooks'

const Container = styled.div`
  ${({isMobile}) => isMobile ? css`` : css`min-width: 1380px;`};
  /* overflow: scroll; */
`

const ContentsContainer = styled.div`
  height: 100%;
`;

function LayoutContainer({ children }) {
  const isMobile = useMobileCheck()

  return (
    <Container isMobile={isMobile} id="#wrap">
      { isMobile ? <MobileHeader /> : <Header />}

      <ContentsContainer>{children}</ContentsContainer>
      
      { isMobile ? <MobileFooter/> : <Footer/>}
    </Container>
  );
}

export default LayoutContainer;


