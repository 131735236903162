import { lazy } from 'react';

function componentLoader(lazyComponent, attemptsLeft = 2) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1000);
      });
  });
}

const MainPage = lazy(() => componentLoader(() => import ('pages/Main/Main')));
const MobileMainPage = lazy(() => componentLoader(() => import ('pages/MobileMain')));

const UnsecretFriendPage = lazy(() => componentLoader(() => import ('pages/Friend/Friend')));

export default {
  MainPage,
  MobileMainPage,

  UnsecretFriendPage
};